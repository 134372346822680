<template>
    <section class="login">
        <div class="half_inner height_half_inner">
            <header class="section_header header_flex">
                <h2 class="text_xxl text_white text_300">Login</h2>
                <div class="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                        </li>
                        <li>
                            <span class="breadcrumbs_item">Login</span>
                        </li>
                    </ul>
                </div>
            </header>
            <div class="content_block padded rounded white">
                <div class="login_block grid grid_pad flex_space">
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box">
                            <h2 class="text_xl mb30">¡Hola!, para poder comprar debes entrar en tu cuenta.</h2>
                            <p class="mb30">¿Nuevo en Shopohs?, <router-link to="/registro" style="text-decoration: underline;">Registrate aquí</router-link></p>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box">
                            <form class="form" @submit.prevent="login">
                                <div class="input_block">
                                    <input type="email" placeholder="Email" v-model="email" maxlength="50"/>
                                </div>
                                <div class="input_block">
                                    <input type="password" placeholder="Contraseña" v-model="password"/>
                                </div>
                                <div class="input_block">
                                    <button type="text">Enviar</button>
                                </div>
                                <div class="input_block">
                                    <div class="legal_line">
                                        <p class="legal">Todos los campos son imprescindibles</p>
                                        <router-link to="/recuperar" style="font-size: 12px;text-decoration: underline;">Olvidé mi contraseña</router-link>
                                    </div>
                                </div>
                                <div class="input_block" v-if="msg">
                                    <div class="message" :class="msg.type">
                                        <h3>{{msg.text}}</h3>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Login',
    data: function(){
        return {
            email: null,
            password: null,
            msg: null
        }
    },
    methods: {
        login: function(){
            if(!this.email || this.email.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el email'
                }
                return;
            }
            else if(!this.password || this.password.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta la contraseña'
                }
                return;
            }
            this.msg = null;

            axios
                .post(process.env.VUE_APP_URL+'login', {
                    email: this.email,
                    password: this.password
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$store.commit('login', rs.data);
                        let url = '/';
                        if(window.oldUrl){
                            url = window.oldUrl;
                            delete window.oldUrl;
                        }
                        this.$router.push(url);
                    }
                    else{
                        this.msg = {
                            type: 'ko',
                            text: rs.msg
                        }
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        }
    }
}
</script>